<!--漏检原因-->
<template>
  <div class="leak-reason">
    <van-nav-bar
      title="漏检原因"
      left-arrow
      :border="false"
      @click-left="onClickLeft"
    />
    <div ref="leakReasonWrapper" class="leak-reason-wrapper">
      <van-form
        ref="form"
        :show-error-message="false"
        input-align="left"
        label-width="30vw"
      >
        <van-field
          v-model="supplementReason"
          :rules="[{ required: true, message: '请输入漏检原因' }]"
          autofocus
          :autosize="{
            maxHeight,
            minHeight: 160
          }"
          type="textarea"
          maxlength="1000"
          placeholder="请输入漏检原因"
          show-word-limit
        />
        <div ref="buttonWrapper" class="button-wrapper">
          <van-button type="info" @click="onSubmit">提交</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { operateDialog, operateMessage } from "@/utils";
import { applySupplementById } from "@/api/psm/inspectionNew";
import { Dialog, Toast } from "vant";

export default {
  name: "LeakReason",
  data() {
    return {
      supplementReason: "",
      maxHeight: 400
    };
  },
  computed: {
    supplementApprove() {
      return Number(this.$route.query.supplementApprove) || 0;
    },
    id() {
      return this.$route.params.id;
    }
  },
  mounted() {
    this.calcMaxHeight();
  },
  methods: {
    async onSubmit() {
      const v = await this.$refs.form.validate();
      if (v) return;
      let data = {
        id: this.id,
        supplementReason: this.supplementReason
      };
      const r = await applySupplementById(data);
      if (r) {
        let msg = this.supplementApprove
          ? "提交成功，进入审批流程。"
          : "提交成功，请及时完成补录。";
        Toast.success(msg);
        this.$router.push({ name: "InspectionManagementDispatch" });
      }
    },
    calcMaxHeight() {
      // UI需求 输入框最大高度离提交按钮24px
      const fh = this.$refs.leakReasonWrapper?.offsetHeight; // 父元素高度
      const sh = this.$refs.buttonWrapper?.offsetHeight; // 子元素高度
      this.maxHeight = fh - sh - 24 - 16 - 30; // 按钮底部留了16px 再减30是由于maxHeight 不包含字数统计及下横线
    },
    async onClickLeft() {
      await operateDialog({
        title: "提示",
        message: "数据尚未保存，确认返回？"
      }).catch(_ => {});
      history.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.leak-reason {
  height: 100%;
  .leak-reason-wrapper {
    position: relative;
    height: calc(100% - 58px);
    .button-wrapper {
      width: 100%;
      position: absolute;
      bottom: 16px;
      text-align: center;
      ::v-deep {
        .van-button {
          width: 90%;
          height: 44px;
          border-radius: 27px;
        }
      }
    }
  }
}
</style>
